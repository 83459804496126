import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import profileimg from 'assets/images/profileimg.png';
import HeaderLogo from 'assets/images/RiseIt.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import { AdminPrivateRoutes } from 'config';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import {
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getSingleData } from 'services/CommonServices';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { EditPayInformationPopUpForm, EditProfilePopUpForm } from 'types';
import { RestrictionProd, roundOfData } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { patchRequest } from 'utils/Handlers';
import {
  EditPayInformationSchema,
  EditTimeSheetSchema,
} from 'utils/ValidatorSchema';

import PaySheet from '../../common/PaySheet';
import TimesheetComponent from '../../common/Timesheet';
import useStyles from './style';

const style1 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const style2 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  height: '90vh',
  left: '55%',
  maxHeight: 750,
  overflow: 'scroll',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const style3 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  height: 500,
  left: '55%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display Regular',
    fontSize: 12,
    padding: 8,
    paddingBottom: 12,
    paddingTop: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fff',
    borderColor: '#f2f2f2',
    borderWidth: 6,
    color: '#241A2E',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 14,
    height: 50,
    padding: 5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #d4d4d6 !important',
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    lineHeight: 'inherit',
    padding: '15px',
    verticalAlign: 'center',
  },
}));

export const ConsultantsTimesheet: React.FC<any> = () => {
  const classes = useStyles();
  const { consultantId } = useParams();
  const [userInfo, setUserInfo] = React.useState<any>({});
  const [isConsultantDoNotPay, setIsConsultantDoNotPay] =
    React.useState<boolean>();
  const [leaveHistory, setLeaveHistory] = React.useState<any>([]);
  const [tab, setTab] = React.useState<number>(1);
  const [radio, setRadio] = React.useState<string>('both');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [timeSheetRestricted, setTimeSheetRestricted] =
    useState<boolean>(false);
  const [message, setMessage] = React.useState<string>(
    'You have not submitted your timesheets/hours for a month. This is a first written warning that if you do not submit your timesheet/hours by X day, your employment may be terminated for violation of company policy.',
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [collectionFrequencyArr, setCollectionFrequencyArr] = React.useState(
    [],
  );
  const [openFrequency, setOpenFrequency] = React.useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = useState<boolean>(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const sendRequest = () => {
    setModalOpen(true);
  };

  React.useEffect(() => {
    setUserInfo({});
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantId]);

  const getConsultantDoNotPay = useCallback(() => {
    getSingleData(
      '',
      `${MASTER_ENDPOINT.Consultants}/${consultantId}/${MASTER_ENDPOINT.ConsultantsDoNotPay}`,
      true,
    ).then((resp: any) => {
      const doNotPay = resp?.doNotPay;
      if (doNotPay !== undefined || doNotPay !== null) {
        setIsConsultantDoNotPay(doNotPay);
      } else {
        toast.error('Failed to get consultant do not pay status');
      }
    });
  }, [consultantId]);

  React.useEffect(() => {
    getConsultantDoNotPay();
  }, [getConsultantDoNotPay]);

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    setValue: setValue1,
  } = useForm<EditPayInformationPopUpForm>({
    defaultValues: {
      additionalPay: '',
      basePay: '',
      baseWage: '',
      doNotPay: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(EditPayInformationSchema),
  });

  const [loading2, setLoading2] = React.useState<boolean>(false);

  const submitFrequency = () => {
    setLoading2(true);
    updateData(
      '',
      { collectionFrequency: collectionFrequencyArr },
      MASTER_ENDPOINT.ProjectCollectionFrequency,
      true,
    )
      .then((resp: any) => {
        setLoading2(false);
        setOpenFrequency(false);
        getData();
      })
      .catch((err: any) => {
        setLoading2(false);
      });
  };

  const submitForm1 = (data: any) => {
    setLoading2(true);
    // const formData = new FormData();
    const basePay: any = parseFloat(data.basePay);
    const baseWage: any = parseFloat(data.baseWage);
    const additionalPay: any = parseFloat(data.additionalPay);
    const payloadData = {
      additionalPay,
      basePay,
      baseWage,
      email: userInfo?.email,
      empCode: userInfo.empCode,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      roleId: userInfo.roleId,
      status: 'true',
    };
    // formData.append('additionalPay', additionalPay);
    // formData.append('basePay', basePay);
    // formData.append('baseWage', baseWage);
    // formData.append('doNotPay', doNotPay);
    // formData.append('email', userInfo?.login?.email);
    // formData.append('firstName', userInfo.firstName);
    // formData.append('lastName', userInfo.lastName);
    // formData.append('empCode', userInfo.empCode);
    // formData.append('roleId', userInfo.roleId);
    // formData.append('status', 'true');
    updateData(
      userInfo.id,
      payloadData,
      `${MASTER_ENDPOINT.Admins}/profile/pay`,
      true,
    )
      .then((resp: any) => {
        setLoading2(false);
        handleClose2();
        getData();
      })
      .catch((err: any) => {
        setLoading2(false);
      });
  };

  const getData = () => {
    getAllListData(
      `${MASTER_ENDPOINT.Admins}/consultant-profile/?id=${String(
        consultantId,
      )}`,
    )
      .then((resp: any) => {
        if (
          resp?.data?.[0].consultantStatus === 'Terminated' ||
          resp?.data?.[0].consultantStatus === 'Closed'
        ) {
          setTimeSheetRestricted(true);
          setTab(!RestrictionProd ? 2 : 3);
        }
        setUserInfo(resp?.data?.[0]);
        setValue('firstName', resp?.data?.[0]?.firstName);
        setValue('lastName', resp?.data?.[0]?.lastName);
        setValue('email', resp?.data?.[0]?.login?.email);
        setPhoneNumber(resp?.data?.[0]?.phoneNumber);
        setValue('city', resp?.data?.[0].city);
        setValue('state', resp?.data?.[0].state);
        setValue('workAuth', resp?.data?.[0].workAuth);
        setValue(
          'eligibleForSickLeave',
          resp?.data?.[0].eligibleForSickLeave ? 'yes' : 'no',
        );
        setValue('eligibleSickHours', resp?.data?.[0].eligibleSickHours);
        setValue('timesheetStatus', resp?.data?.[0].timesheetStatus);
        // setValue('collectionFrequency', resp?.data?.[0].collectionFrequency);
        setValue1(
          'basePay',
          resp?.data?.[0].basePay ? parseFloat(resp?.data?.[0].basePay) : 0,
        );
        setValue1(
          'baseWage',
          resp?.data?.[0].baseWage ? parseFloat(resp?.data?.[0].baseWage) : 0,
        );
        setValue1(
          'additionalPay',
          resp?.data?.[0].additionalPay
            ? parseFloat(resp?.data?.[0].additionalPay)
            : 0,
        );
        setValue1('doNotPay', resp?.data?.[0].doNotPay ? 'yes' : 'no');
      })
      .catch((err: any) => {});
  };

  // Set do not pay for consultant
  const handleConsultantDoNotPay = async () => {
    // based on consultant's do not pay status, pick the endpoint to call to change the status
    const doNotPayEndpoint = isConsultantDoNotPay
      ? `${MASTER_ENDPOINT.Consultants}/${consultantId}/${MASTER_ENDPOINT.ConsultantsPay}`
      : `${MASTER_ENDPOINT.Consultants}/${consultantId}/${MASTER_ENDPOINT.ConsultantsDoNotPay}`;
    try {
      const response = await patchRequest({ url: doNotPayEndpoint });
      if (response.status === 200) {
        setIsConsultantDoNotPay(!isConsultantDoNotPay);
        toast.success(
          `Consultant ${
            isConsultantDoNotPay ? 'removed from' : 'added to'
          } do not pay list`,
        );
      }
    } catch (error) {
      toast.error('Failed to update consultant do not pay status');
    }
  };

  const {
    handleSubmit: handleSubmit,
    control: control,
    formState,
    setValue,
    watch,
  } = useForm<EditProfilePopUpForm>({
    defaultValues: {
      city: '',
      eligibleForSickLeave: '',
      eligibleSickHours: '',
      // collectionFrequency: '',
      email: '',
      firstName: '',
      lastName: '',
      state: '',
      timesheetStatus: '',
      workAuth: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(EditTimeSheetSchema),
  });
  const submitForm = (data: any) => {
    setLoading(true);
    const profilePayload = {
      city: data?.city ? data?.city : '',
      collectionFrequency: data?.collectionFrequency
        ? data?.collectionFrequency
        : '',
      countryCode: phoneNumber ? `+${countryCode}` : '',
      eligibleForSickLeave: data?.eligibleForSickLeave === 'yes' ? true : false,
      eligibleSickHours: data?.eligibleSickHours,
      email: data.email,
      empCode: userInfo.empCode,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: phoneNumber ? phoneNumber : '',
      roleId: userInfo.roleId,
      state: data?.state ? data?.state : '',
      status: 'true',
      timesheetStatus: data?.timesheetStatus ? data?.timesheetStatus : '',
      workAuth: data?.workAuth ? data?.workAuth : '',
    };
    // if (data?.eligibleForSickLeave === 'no') {
    //   delete profilePayload.eligibleSickHours;
    // }
    // const formData = new FormData();
    // formData.append('status', 'true');
    // formData.append('email', data.email);
    // formData.append('firstName', data.firstName);
    // formData.append('lastName', data.lastName);
    // formData.append('empCode', userInfo.empCode);
    // formData.append('roleId', userInfo.roleId);

    // if (phoneNumber) {
    //   formData.append('phoneNumber', phoneNumber);
    //   formData.append('countryCode', `+${countryCode}`);
    // } else {
    //   formData.append('phoneNumber', '');
    //   formData.append('countryCode', '');
    // }
    // formData.append('city', data?.city ? data?.city : '');
    // formData.append('state', data?.state ? data?.state : '');
    // formData.append('workAuth', data?.workAuth ? data?.workAuth : '');
    // formData.append(
    //   'timesheetStatus',
    //   data?.timesheetStatus ? data?.timesheetStatus : '',
    // );
    // formData.append(
    //   'collectionFrequency',
    //   data?.collectionFrequency ? data?.collectionFrequency : '',
    // );
    updateData(
      userInfo.id,
      profilePayload,
      `${MASTER_ENDPOINT.Admins}/profile`,
      true,
    )
      .then((resp: any) => {
        setLoading(false);
        handleClose();
        getData();
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const submitRequest = () => {
    setLoading(true);
    handleClose1();
    const bodyObject = {
      bodyContent: message,
      branches: [],
      type: radio,
      userId: [consultantId],
    };
    addNewData(
      bodyObject,
      MASTER_ENDPOINT.Consultants + '/send-request',
      {},
      true,
    )
      .then((resp: any) => {
        setLoading(false);
        setModalOpen(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  // const getUserInfo = () => {
  //   getAllListData(`${MASTER_ENDPOINT.Admins}/?id=${String(consultantId)}`)
  //     .then((response: any) => {
  //       setUserInfo(response.data[0]);
  //     })
  //     .catch((e) => {});
  // };

  const getLeaveHistory = (userId) => {
    getAllListData(MASTER_ENDPOINT.Leaves + '?userId=' + userId)
      .then((resp: any) => {
        setLeaveHistory(resp?.data);
      })
      .catch((err: any) => {});
  };

  const location = useLocation();
  const [nextUserId, setNextUserId] = React.useState('');
  const [previousUserId, setPreviousUserId] = React.useState('');
  const [userIds, setUserIds] = React.useState([]);
  useEffect(() => {
    setNextUserId('');
    setPreviousUserId('');
    if (location?.state) {
      if (location?.state?.userIds && location?.state?.userIds?.length) {
        setUserIds(location?.state?.userIds);
        const fIndex = location?.state?.userIds.findIndex(
          (item: any) => item.userId === consultantId,
        );
        if (fIndex !== -1) {
          if (Number(fIndex) + 1 !== location?.state?.userIds?.length) {
            setNextUserId(location?.state?.userIds[Number(fIndex) + 1]?.userId);
          }
          if (fIndex !== 0) {
            setPreviousUserId(
              location?.state?.userIds[Number(fIndex) - 1]?.userId,
            );
          }
        }
      }
      if (location?.state?.from) {
        if (location?.state?.from === 'projects') {
          setTab(1);
        } else {
          setTab(2);
        }
      }
    }
  }, [location, consultantId]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('type')) {
      setTab(2);
    }
  }, [searchParams]);

  useEffect(() => {
    if (consultantId != undefined && consultantId != null) {
      // getUserInfo();
      getLeaveHistory(consultantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantId]);

  const downloadPdf = () => {
    const unit = 'pt';
    const size = 'A2'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = 'LEAVE HISTORY';
    const headers = [
      [
        'Status',
        'Work Auth',
        'Leave App Recd',
        'Leave Start Date',
        'Leave End Date',
        'ADP Updated',
        'Paid? (Y/N/P)',
        'Remote Work (Y/N)',
        'Leave Type',
        'State',
        'Obtain I-94',
        'Comments',
      ],
    ];
    const data = leaveHistory.map((row: any, index: number) => {
      let leaveTypesText = '';
      if (row?.leaveTypes && row?.leaveTypes.length) {
        row?.leaveTypes.map((item: any) => {
          leaveTypesText += item?.leaveType?.type + ', ';
        });
      }
      if (leaveTypesText) {
        leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
      }
      return [
        row.status === 'approved' &&
        (moment(moment().format('YYYY-MM-DD')).isBetween(
          moment(row.fromDate).format('YYYY-MM-DD'),
          moment(row.toDate).format('YYYY-MM-DD'),
        ) ||
          moment(moment().format('YYYY-MM-DD')).isSame(
            moment(row.fromDate).format('YYYY-MM-DD'),
          ) ||
          moment(moment().format('YYYY-MM-DD')).isSame(
            moment(row.toDate).format('YYYY-MM-DD'),
          ))
          ? 'On Leave'
          : row.status === 'pending' || row.status === 'approved'
          ? 'Planned'
          : row.status === 'returned'
          ? 'Returned'
          : row.status === 'denied'
          ? 'Denied'
          : row.status === 'extended'
          ? 'Extended'
          : row.status,
        userInfo?.workAuth,
        moment(row.createdAt).format('MM/DD/YYYY'),
        moment(row.fromDate).format('MM/DD/YYYY'),
        moment(row.toDate).format('MM/DD/YYYY'),
        row.adpUpdated,
        row.wishToBePaidOnLeave === 'yes'
          ? 'Y'
          : row.wishToBePaidOnLeave === 'no'
          ? 'N'
          : row.wishToBePaidOnLeave === 'partialyPaid'
          ? 'P'
          : '',
        row.workingWhileOnLeave === null
          ? ''
          : row.workingWhileOnLeave
          ? 'Y'
          : 'N',
        leaveTypesText,
        row.onLeaveCountry,
        row.obtainI94 ? capitalizeFirstLetter(row.obtainI94) : '',
        row.comments,
      ];
    });
    const content = {
      body: data,
      head: headers,
      startY: 50,
    };
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    const img = new Image();
    const src = HeaderLogo;
    img.src = src;
    doc.addImage(img, 'png', 1077, 5, 110, 50);
    doc.setFontSize(12);
    doc.text(title, width / 2, 40, { align: 'center' });
    autoTable(doc, {
      ...content,
      bodyStyles: {
        lineColor: 'black',
        lineWidth: 0.2,
      },
      headStyles: {
        lineColor: 'black',
        lineWidth: 1,
      },
      margin: { horizontal: 20 },
      startY: 60,
      theme: 'plain',
    });
    const pageCount = doc.getNumberOfPages();
    doc.setFontSize(12);
    // For each page, print the page number and the total pages
    for (let i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      // Print Page 1 of 4 for example
      doc.text(
        'Page ' + String(i) + ' of ' + String(pageCount),
        width - 60,
        height - 10,
        {
          align: 'center',
        },
      );
      doc.text(moment().format('MM/DD/YYYY'), 60, height - 10, {
        align: 'center',
      });
    }
    doc.save('LeaveHistory.pdf');
  };

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'simple-popover' : undefined;

  const { payrollSummaryId }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  return (
    <Typography component={'div'}>
      <Grid container className={classes.header2}>
        <Grid item md={3}>
          <Typography component={'div'} sx={{ paddingLeft: '15px' }}>
            <NavLink
              to={
                location?.state?.from === 'PayrollSummary'
                  ? AdminPrivateRoutes.PAYROLLSUMMARY
                  : location?.state?.from === 'projects'
                  ? AdminPrivateRoutes.PROJECTS
                  : location?.state?.from === 'submission'
                  ? AdminPrivateRoutes.CONSULTANTS
                  : location?.state?.from
                  ? AdminPrivateRoutes.CONSULTANTSSUB
                  : AdminPrivateRoutes.CONSULTANTS
              }
              state={
                location?.state?.from === 'PayrollSummary'
                  ? { payrollSummaryId: payrollSummaryId }
                  : {}
              }
              className={classes.backText}>
              Back to{' '}
              {location?.state?.from === 'PayrollSummary'
                ? 'Payroll Summary'
                : location?.state?.from === 'projects'
                ? 'Projects'
                : location?.state?.from === 'submission'
                ? 'Consultants'
                : location?.state?.from
                ? 'Payroll Questions'
                : 'Consultants'}
            </NavLink>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <List
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              padding: '0px',
            }}>
            {timeSheetRestricted ? (
              <Typography />
            ) : (
              <ListItem
                sx={{
                  borderBottom: tab === 1 ? '3px solid #DB2426' : 'inherit',
                  marginTop: tab === 1 ? '4px' : '0px',
                  padding: '15px',
                  width: 'inherit',
                }}>
                <ListItemText>
                  <a
                    href={'javascript: void(0);'}
                    style={{
                      color: tab === 1 ? '#DB2426' : '#292929',
                      fontFamily: 'Red Hat Display Bold',
                      fontSize: '15px',
                      textDecoration: 'inherit',
                    }}
                    onClick={() => setTab(1)}>
                    TIMESHEET
                  </a>
                </ListItemText>
              </ListItem>
            )}
            {!RestrictionProd && (
              <ListItem
                sx={{
                  borderBottom: tab === 2 ? '3px solid #DB2426' : 'inherit',
                  marginTop: tab === 2 ? '4px' : '0px',
                  padding: '15px',
                  width: 'inherit',
                }}>
                <ListItemText>
                  <a
                    href={'javascript: void(0);'}
                    style={{
                      color: tab === 2 ? '#DB2426' : '#292929',
                      fontFamily: 'Red Hat Display Bold',
                      fontSize: '15px',
                      textDecoration: 'inherit',
                    }}
                    onClick={() => setTab(2)}>
                    PAYSHEET
                  </a>
                </ListItemText>
              </ListItem>
            )}
            <ListItem
              sx={{
                borderBottom: tab === 3 ? '3px solid #DB2426' : 'inherit',
                marginTop: tab === 3 ? '4px' : '0px',
                padding: '15px',
                width: 'inherit',
              }}>
              <ListItemText>
                <a
                  href={'javascript: void(0);'}
                  style={{
                    color: tab === 3 ? '#DB2426' : '#292929',
                    fontFamily: 'Red Hat Display Bold',
                    fontSize: '15px',
                    textDecoration: 'inherit',
                  }}
                  onClick={() => setTab(3)}>
                  PROFILE
                </a>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={3} />
      </Grid>
      {tab === 1 ? (
        <Typography component={'div'}>
          <Typography component={'div'} className={classes.subheader1}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item md={3.4}>
                <Typography className={classes.profilebox} component={'div'}>
                  {userInfo?.profileImage?.id ? (
                    <img
                      style={{ borderRadius: '10%', padding: 0 }}
                      src={`${process.env.REACT_APP_APIURL}${
                        MASTER_ENDPOINT.Files
                      }/${String(userInfo?.profileImage?.id)}`}
                    />
                  ) : (
                    <img
                      src={profileimg}
                      style={{ borderRadius: '10%', padding: 0 }}
                    />
                  )}
                  {userInfo?.firstName && (
                    <Typography component={'span'}>
                      {userInfo?.lastName}, {userInfo?.firstName}:{' '}
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid item md={8.5} justifyContent="end">
                <Stack direction="row" justifyContent="end" alignItems="center">
                  <Typography>
                    Company:{' '}
                    <span style={{ color: '#241A2E' }}>
                      {userInfo?.ultraStaffDbName === 'SmartWorks'
                        ? 'Smart Works'
                        : userInfo?.ultraStaffDbName}
                    </span>
                  </Typography>
                  <Typography>
                    Required Timesheets:
                    <span style={{ color: '#241A2E' }}>
                      {userInfo?.timesheetStatus}
                    </span>
                  </Typography>
                  <Typography style={{ paddingLeft: '20px' }}>
                    <ButtonGroup variant="contained" aria-label="split button">
                      <Button onClick={handleClick1}>Send Request</Button>
                      <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        onClick={handleClick1}
                        aria-haspopup="menu">
                        <KeyboardArrowDownSharpIcon />
                      </Button>
                      <Popover
                        className="commonpopover"
                        id={id1}
                        open={open1}
                        anchorEl={anchorEl1}
                        onClose={handleClose1}
                        anchorOrigin={{
                          horizontal: 'left',
                          vertical: 'bottom',
                        }}>
                        <Typography sx={{ p: 2 }}>
                          <RadioGroup
                            name="use-radio-group"
                            defaultValue="first">
                            <FormControlLabel
                              value="both"
                              label="Both"
                              onChange={(e: any) => setRadio(e?.target?.value)}
                              control={
                                <Radio
                                  value="both"
                                  sx={{
                                    '&.Mui-checked': {
                                      color: '#005EBF',
                                    },
                                    color: '#3e3d3d',
                                  }}
                                  checked={radio === 'both'}
                                />
                              }
                            />
                            <FormControlLabel
                              value="hours"
                              label="Hours"
                              onChange={(e: any) => setRadio(e?.target?.value)}
                              control={
                                <Radio
                                  value="hours"
                                  sx={{
                                    '&.Mui-checked': {
                                      color: '#005EBF',
                                    },
                                    color: '#3e3d3d',
                                  }}
                                  checked={radio === 'hours'}
                                />
                              }
                            />
                            <FormControlLabel
                              value="timesheets"
                              label="TimeSheet"
                              onChange={(e: any) => setRadio(e?.target?.value)}
                              control={
                                <Radio
                                  value="timesheets"
                                  sx={{
                                    '&.Mui-checked': {
                                      color: '#005EBF',
                                    },
                                    color: '#3e3d3d',
                                  }}
                                  checked={radio === 'timesheets'}
                                />
                              }
                            />
                          </RadioGroup>
                          <Button onClick={sendRequest} variant="contained">
                            Send Request
                          </Button>
                          <Modal
                            open={modalOpen}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <Box sx={style1} className="model-widget">
                              <Typography id="modal-modal-title" component="h2">
                                Message for employee
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}>
                                <Typography component={'div'}>
                                  <label>Message</label>
                                  <TextField
                                    sx={{ p: 0 }}
                                    className="common-textarea-timereq"
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={3}
                                    inputProps={{
                                      maxLength: 250,
                                    }}
                                    value={message}
                                    onChange={(e: any) =>
                                      setMessage(e?.target?.value)
                                    }
                                  />
                                </Typography>
                              </Typography>
                              <Typography sx={{ paddingTop: '15px' }} />
                              <Divider />
                              <Typography sx={{ paddingTop: '15px' }} />
                              <Typography
                                className="model-btn"
                                component={'div'}>
                                <Button
                                  onClick={() => setModalOpen(false)}
                                  variant="text">
                                  Cancel
                                </Button>
                                <CustomButton
                                  variant="contained"
                                  isStyle={true}
                                  name={'Send Request'}
                                  form={'sendReq'}
                                  onPress={submitRequest}
                                  loading={loading}
                                />
                              </Typography>
                            </Box>
                          </Modal>
                        </Typography>
                      </Popover>
                      {/* <PopoverAlert /> */}
                      {/* <Button
                        size="small"
                        // aria-controls={open ? 'split-button-menu' : undefined}
                        // aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu">
                        <KeyboardArrowDownSharpIcon />
                      </Button> */}
                    </ButtonGroup>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Typography>
          <Typography component={'div'} className={classes.defaulttable}>
            <Grid container sx={{ paddingTop: '100px' }}>
              <TimesheetComponent
                consultantId={consultantId}
                showPaid={true}
                showApproved={true}
                isAdminScreen={true}
                showHours={false}
                jobWcCode={''}
              />
            </Grid>
          </Typography>
        </Typography>
      ) : tab === 2 ? (
        // <PaySheet consultantId={consultantId} />
        <Typography component={'div'}>
          <Typography component={'div'} className={classes.subheader1}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item md={3.4}>
                <Typography className={classes.profilebox} component={'div'}>
                  {userInfo?.profileImage?.id ? (
                    <img
                      style={{ borderRadius: '10%', padding: 0 }}
                      src={`${process.env.REACT_APP_APIURL}${
                        MASTER_ENDPOINT.Files
                      }/${String(userInfo?.profileImage?.id)}`}
                    />
                  ) : (
                    <img
                      src={profileimg}
                      style={{ borderRadius: '10%', padding: 0 }}
                    />
                  )}
                  {userInfo?.firstName && (
                    <Typography component={'span'}>
                      {userInfo?.lastName}, {userInfo?.firstName}
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                textAlign={'end'}
                sx={{ display: 'flex', justifyContent: 'end' }}>
                <Typography>
                  Company:{' '}
                  <span style={{ color: '#241A2E' }}>
                    {userInfo?.ultraStaffDbName === 'SmartWorks'
                      ? 'Smart Works'
                      : userInfo?.ultraStaffDbName}
                  </span>
                </Typography>
                {previousUserId && (
                  <NavLink
                    to={`${String(
                      AdminPrivateRoutes.CONSULTANTS,
                    )}/profile/${String(previousUserId)}`}
                    state={{ userIds: userIds }}
                    style={{ marginRight: '20px' }}
                    className={classes.backText}>
                    Previous
                  </NavLink>
                )}
                {nextUserId ? (
                  <NavLink
                    to={`${String(
                      AdminPrivateRoutes.CONSULTANTS,
                    )}/profile/${String(nextUserId)}`}
                    state={{ userIds: userIds }}
                    className={classes.backText}>
                    Next
                  </NavLink>
                ) : (
                  <Typography />
                )}
              </Grid>
            </Grid>
          </Typography>
          <Typography component={'div'}>
            <Grid container sx={{ paddingTop: '100px' }}>
              <PaySheet
                consultantId={consultantId}
                from={'admin'}
                userInfo={userInfo}
              />
            </Grid>
          </Typography>
        </Typography>
      ) : (
        <Typography component="div">
          <Stack component="div" className={classes.profilewidgetbox}>
            <Typography
              component="div"
              sx={{ width: '30% !important' }}
              className={classes.profilewidget}>
              <Typography className={classes.profilebox} component={'div'}>
                {userInfo?.profileImage?.id ? (
                  <img
                    style={{ borderRadius: '10%', padding: 0 }}
                    src={`${process.env.REACT_APP_APIURL}${
                      MASTER_ENDPOINT.Files
                    }/${String(userInfo?.profileImage?.id)}`}
                  />
                ) : (
                  <img
                    src={profileimg}
                    style={{ borderRadius: '10%', padding: 0 }}
                  />
                )}
                {userInfo?.firstName && (
                  <Typography component={'span'}>
                    {userInfo?.lastName}, {userInfo?.firstName}
                  </Typography>
                )}
              </Typography>
            </Typography>
            <Typography
              component="div"
              sx={{ width: '25% !important' }}
              className={classes.profilewidget}>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '18.5% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Name
                </Typography>
                <Typography component={'span'} className={classes.label2}>
                  {userInfo?.firstName
                    ? userInfo?.lastName + ', ' + userInfo?.firstName
                    : ''}
                </Typography>
              </Typography>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '18% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Email
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  {userInfo?.email}
                </Typography>
              </Typography>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '18% !important', marginTop: '10px' }}
                  component={'span'}
                  className={classes.label1}>
                  Phone
                </Typography>
                <Typography component={'span'} className={classes.label2}>
                  <Typography component={'span'} className={'phoneLabel'}>
                    <PhoneInput
                      country={'us'}
                      disabled
                      value={userInfo?.phoneNumber}
                      onChange={(value, data: any) => {
                        // setPhoneNumber(value);
                        // setCountryCode(data?.dialCode);
                      }}
                    />
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
            <Typography
              component="div"
              sx={{ width: '18% !important' }}
              className={classes.profilewidget}>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '33% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Address
                </Typography>
                <Typography component={'span'} className={classes.label2}>
                  {userInfo?.city} <br /> {userInfo?.state}
                </Typography>
              </Typography>
            </Typography>
            <Typography
              component="div"
              sx={{ width: '18% !important' }}
              className={classes.profilewidget}>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '45% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Work Auth
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  {userInfo?.workAuth}
                </Typography>
              </Typography>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '45% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Recruiter
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  Fred Williams
                </Typography>
              </Typography>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '45% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Timesheet
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  {userInfo?.timesheetStatus}
                </Typography>
              </Typography>
            </Typography>
            <Typography
              component="div"
              sx={{ width: '18% !important' }}
              className={classes.profilewidget}>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '50% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  # of Projects
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  {userInfo?.consultantProjects
                    ? userInfo?.consultantProjects.length
                    : 0}
                </Typography>
              </Typography>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '50% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  Eligible for Sick Leave?
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  {userInfo?.eligibleForSickLeave ? 'Yes' : 'No'}
                </Typography>
              </Typography>
              <Typography component="div" className={classes.profileinfo}>
                <Typography
                  sx={{ flexBasis: '50% !important' }}
                  component={'span'}
                  className={classes.label1}>
                  (#of hours / per year)
                </Typography>
                <Typography component={'span'} className={classes.label21}>
                  {userInfo?.eligibleSickHours}
                </Typography>
              </Typography>
            </Typography>
            <Typography
              sx={{ width: '3% !important' }}
              component="div"
              className={classes.profilewidget}>
              <a href={'#'} onClick={handleOpen}>
                <Typography
                  component={'span'}
                  className={classes.label1}
                  sx={{ textDecoration: 'underline' }}>
                  Edit
                </Typography>
              </a>
            </Typography>
          </Stack>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <form id={'editProfile'} onSubmit={handleSubmit(submitForm)}>
              <Box sx={style2} className="model-widget">
                <Typography id="modal-modal-title" component="h2">
                  Edit Employee Profile
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  style={{
                    marginBottom: 25,
                  }}>
                  <Typography component={'div'}>
                    <Typography>
                      <label>
                        First Name<span className="errorText">{'*'}</span>
                      </label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="firstName"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.firstName?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>
                        Last Name<span className="errorText">{'*'}</span>
                      </label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="lastName"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.lastName?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>
                        Email
                        {/* <span className="errorText">{'*'}</span> */}
                      </label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="email"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              // error={formState.errors?.email?.message}
                              disabled
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>Phone Number</label>
                      <PhoneInput
                        country={'us'}
                        value={phoneNumber}
                        onChange={(value, data: any) => {
                          setPhoneNumber(value);
                          setCountryCode(data?.dialCode);
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>City</label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="city"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.city?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>State</label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="state"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.state?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>Work Auth</label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="workAuth"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <>
                              <Select
                                style={{ textTransform: 'capitalize' }}
                                className="common-select"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                name={name}
                                ref={ref}
                                inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem
                                  value={'EAD'}
                                  style={{ textTransform: 'capitalize' }}>
                                  EAD
                                </MenuItem>
                                <MenuItem
                                  value={'H-1B'}
                                  style={{ textTransform: 'capitalize' }}>
                                  H-1B
                                </MenuItem>
                                <MenuItem
                                  value={'LPR'}
                                  style={{ textTransform: 'capitalize' }}>
                                  LPR
                                </MenuItem>
                                <MenuItem
                                  value={'N/A'}
                                  style={{ textTransform: 'capitalize' }}>
                                  N/A
                                </MenuItem>
                                <MenuItem
                                  value={'OPT'}
                                  style={{ textTransform: 'capitalize' }}>
                                  OPT
                                </MenuItem>
                                <MenuItem
                                  value={'TN'}
                                  style={{ textTransform: 'capitalize' }}>
                                  TN
                                </MenuItem>
                                <MenuItem
                                  value={'USC'}
                                  style={{ textTransform: 'capitalize' }}>
                                  USC
                                </MenuItem>
                              </Select>
                              {formState.errors?.workAuth?.message && (
                                <FormHelperText className="errorText">
                                  {formState.errors?.workAuth?.message}
                                </FormHelperText>
                              )}
                            </>
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <label>Timesheet Status</label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="timesheetStatus"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <>
                              <Select
                                style={{ textTransform: 'capitalize' }}
                                className="common-select"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                name={name}
                                ref={ref}
                                inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem
                                  value={'S'}
                                  style={{ textTransform: 'capitalize' }}>
                                  S
                                </MenuItem>
                                <MenuItem
                                  value={'A'}
                                  style={{ textTransform: 'capitalize' }}>
                                  A
                                </MenuItem>
                                <MenuItem
                                  value={'S/A'}
                                  style={{ textTransform: 'capitalize' }}>
                                  S/A
                                </MenuItem>
                              </Select>
                              {formState.errors?.workAuth?.message && (
                                <FormHelperText className="errorText">
                                  {formState.errors?.timesheetStatus?.message}
                                </FormHelperText>
                              )}
                            </>
                          );
                        }}
                      />
                    </Typography>

                    <Typography>
                      <label>Eligible for Sick Leave?</label>
                      <Controller
                        control={control}
                        name="eligibleForSickLeave"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <Select
                              style={{ textTransform: 'capitalize' }}
                              className="common-select"
                              value={value}
                              onChange={(e) => {
                                setValue('eligibleSickHours', '0');
                                onChange(e.target.value);
                              }}
                              name={name}
                              ref={ref}
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem value={'yes'}>Yes</MenuItem>
                              <MenuItem value={'no'}>No</MenuItem>
                            </Select>
                          );
                        }}
                      />
                    </Typography>

                    {watch('eligibleForSickLeave') === 'yes' && (
                      <Typography>
                        <label>
                          Eligible for Sick Leave? (#of hours / per year)
                        </label>
                        <Controller
                          control={control}
                          name="eligibleSickHours"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder=""
                                type="number"
                                value={value}
                                className={classes.custominput}
                                name={name}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={
                                  formState.errors?.eligibleSickHours?.message
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                    )}
                    {/* <Typography>
                      <label>Collection Frequency</label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="collectionFrequency"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <>
                              <Select
                                style={{ textTransform: 'capitalize' }}
                                className="common-select"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                name={name}
                                ref={ref}
                                inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem
                                  value={'W'}
                                  style={{ textTransform: 'capitalize' }}>
                                  W
                                </MenuItem>
                                <MenuItem
                                  value={'M'}
                                  style={{ textTransform: 'capitalize' }}>
                                  M
                                </MenuItem>
                                <MenuItem
                                  value={'W/M'}
                                  style={{ textTransform: 'capitalize' }}>
                                  W/M
                                </MenuItem>
                                <MenuItem
                                  value={'BM'}
                                  style={{ textTransform: 'capitalize' }}>
                                  BM
                                </MenuItem>
                                <MenuItem
                                  value={'BW'}
                                  style={{ textTransform: 'capitalize' }}>
                                  BW
                                </MenuItem>
                                <MenuItem
                                  value={'M-t/s'}
                                  style={{ textTransform: 'capitalize' }}>
                                  M-t/s
                                </MenuItem>
                                <MenuItem
                                  value={'W/BW'}
                                  style={{ textTransform: 'capitalize' }}>
                                  W/BW
                                </MenuItem>
                                <MenuItem
                                  value={'Th-Alt. W'}
                                  style={{ textTransform: 'capitalize' }}>
                                  Th-Alt. W
                                </MenuItem>
                              </Select>
                              {formState.errors?.collectionFrequency
                                ?.message && (
                                <FormHelperText className="errorText">
                                  {
                                    formState.errors?.collectionFrequency
                                      ?.message
                                  }
                                </FormHelperText>
                              )}
                            </>
                          );
                        }}
                      />
                    </Typography> */}
                  </Typography>
                </Typography>
                <Divider />
                <Typography
                  className="model-btn"
                  component={'div'}
                  style={{
                    marginTop: 25,
                  }}>
                  <Button variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                  <CustomButton
                    variant="contained"
                    type={'submit'}
                    isStyle={true}
                    name={'Save Changes'}
                    form={'editProfile'}
                    loading={loading}
                  />
                </Typography>
              </Box>
            </form>
          </Modal>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <form id={'payProfile'} onSubmit={handleSubmit1(submitForm1)}>
              <Box sx={style3} className="model-widget">
                <Typography id="modal-modal-title" component="h2">
                  Pay Information
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  style={{
                    marginBottom: 25,
                  }}>
                  <Typography>
                    <label>Base Wage</label>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="baseWage"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CurrencyInput
                            id="input-example"
                            prefix={'$'}
                            placeholder={''}
                            className={'width-100'}
                            defaultValue={value}
                            allowDecimals={true}
                            onValueChange={(e: any) => onChange(e)}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>Base Pay (Biweekly Pay)</label>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="basePay"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CurrencyInput
                            id="input-example"
                            prefix={'$'}
                            placeholder={''}
                            className={'width-100'}
                            defaultValue={value}
                            allowDecimals={true}
                            onValueChange={(e: any) => onChange(e)}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>Additional Pay</label>
                    <Controller
                      control={control1}
                      defaultValue=""
                      name="additionalPay"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CurrencyInput
                            id="input-example"
                            prefix={'$'}
                            placeholder={''}
                            className={'width-100'}
                            defaultValue={value}
                            allowDecimals={true}
                            onValueChange={(e: any) => onChange(e)}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Typography>
                <Divider />
                <Typography
                  className="model-btn"
                  component={'div'}
                  style={{
                    marginTop: 25,
                  }}>
                  <Button variant="text" onClick={handleClose2}>
                    Cancel
                  </Button>
                  <CustomButton
                    variant="contained"
                    type={'submit'}
                    isStyle={true}
                    name={'Save Changes'}
                    form={'payProfile'}
                    loading={loading2}
                  />
                </Typography>
              </Box>
            </form>
          </Modal>
          <Divider />
          <Grid container sx={{ padding: '10px', paddingTop: '10px' }}>
            <Grid item md={12}>
              <Typography
                sx={{ paddingRight: '15px', textAlign: 'end' }}
                component="div">
                <a
                  href={'#'}
                  onClick={() => {
                    if (openFrequency) {
                      submitFrequency();
                    } else {
                      const pushCollectionData = [];
                      userInfo?.consultantProjects.map((item: any) => {
                        if (item?.collectionFrequency) {
                          pushCollectionData.push({
                            collectionFrequency: item?.collectionFrequency,
                            id: item.id,
                          });
                        }
                      });
                      setCollectionFrequencyArr([...pushCollectionData]);
                      setOpenFrequency(true);
                    }
                  }}>
                  <Typography
                    component={'span'}
                    className={classes.label1}
                    sx={{ textDecoration: 'underline' }}>
                    {openFrequency ? 'Save' : 'Edit'}
                  </Typography>
                </a>
              </Typography>
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Projects</StyledTableCell>
                      <StyledTableCell>Spec Start</StyledTableCell>
                      <StyledTableCell>Bill to:</StyledTableCell>
                      <StyledTableCell>P/E</StyledTableCell>
                      <StyledTableCell>End Client</StyledTableCell>
                      <StyledTableCell>Taxing State</StyledTableCell>
                      <StyledTableCell>Current Rate</StyledTableCell>
                      <StyledTableCell>Discount</StyledTableCell>
                      <StyledTableCell>Adj. Rate</StyledTableCell>
                      <StyledTableCell>Admin Fee</StyledTableCell>
                      <StyledTableCell>Pay Code</StyledTableCell>
                      <StyledTableCell>Collection Frequency</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userInfo?.consultantProjects &&
                      userInfo?.consultantProjects.map(
                        (row: any, index: number) => {
                          const filterIndex = collectionFrequencyArr.findIndex(
                            (item: any) => item.id === row.id,
                          );

                          return (
                            <StyledTableRow key={row.index}>
                              <StyledTableCell
                                sx={{
                                  fontFamily: 'Red Hat Display Bold !important',
                                  fontSize: '16px !important',
                                  paddingLeft: '20px !important',
                                }}
                                align="left">
                                {row?.project}
                              </StyledTableCell>
                              <StyledTableCell>
                                {moment(row.startDate).format('MM-DD-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell>{row.branch}</StyledTableCell>
                              <StyledTableCell>
                                {moment(row.endDate).format('MM-DD-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row?.clientName}
                              </StyledTableCell>
                              <StyledTableCell />
                              <StyledTableCell>
                                {roundOfData(row.billRate)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {roundOfData(row.payRate)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {roundOfData(row.payRate)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {roundOfData(row.payRate)}
                              </StyledTableCell>
                              <StyledTableCell>{row.jobWcCode}</StyledTableCell>
                              <StyledTableCell>
                                {openFrequency ? (
                                  <Select
                                    style={{ textTransform: 'capitalize' }}
                                    className="common-select"
                                    label="Select"
                                    value={
                                      filterIndex !== -1
                                        ? collectionFrequencyArr[filterIndex]
                                            .collectionFrequency
                                        : ''
                                    }
                                    onChange={(e) => {
                                      if (filterIndex !== -1) {
                                        collectionFrequencyArr[
                                          filterIndex
                                        ].collectionFrequency = e.target.value;
                                      } else {
                                        collectionFrequencyArr.push({
                                          collectionFrequency: e.target.value,
                                          id: row.id,
                                        });
                                      }
                                      setCollectionFrequencyArr([
                                        ...collectionFrequencyArr,
                                      ]);
                                    }}
                                    inputProps={{
                                      'aria-label': 'Without label',
                                    }}>
                                    <MenuItem
                                      value={'W'}
                                      style={{ textTransform: 'capitalize' }}>
                                      W
                                    </MenuItem>
                                    <MenuItem
                                      value={'M'}
                                      style={{ textTransform: 'capitalize' }}>
                                      M
                                    </MenuItem>
                                    <MenuItem
                                      value={'W/M'}
                                      style={{ textTransform: 'capitalize' }}>
                                      W/M
                                    </MenuItem>
                                    <MenuItem
                                      value={'BM'}
                                      style={{ textTransform: 'capitalize' }}>
                                      BM
                                    </MenuItem>
                                    <MenuItem
                                      value={'BW'}
                                      style={{ textTransform: 'capitalize' }}>
                                      BW
                                    </MenuItem>
                                    <MenuItem
                                      value={'M-t/s'}
                                      style={{ textTransform: 'capitalize' }}>
                                      M-t/s
                                    </MenuItem>
                                    <MenuItem
                                      value={'W/BW'}
                                      style={{ textTransform: 'capitalize' }}>
                                      W/BW
                                    </MenuItem>
                                    <MenuItem
                                      value={'Th-Alt. W'}
                                      style={{ textTransform: 'capitalize' }}>
                                      Th-Alt. W
                                    </MenuItem>
                                  </Select>
                                ) : (
                                  row?.collectionFrequency
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        },
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container sx={{ padding: '10px', paddingTop: '10px' }}>
            <Grid item md={12}>
              <Typography component={'span'} className={classes.head2}>
                Pay Information
              </Typography>
            </Grid>

            <ul className={classes.payinfo}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '8px 0px 8px 10px',
                  }}>
                  <li style={{ alignSelf: 'center' }}>
                    <Typography
                      component={'span'}
                      className={classes.label1}
                      sx={{ display: 'flex' }}>
                      <Typography
                        component={'span'}
                        className={classes.label1}
                        sx={{ width: '80px !important' }}>
                        Base Wage:
                      </Typography>
                      <Typography
                        component={'span'}
                        className={classes.label2}
                        sx={{ paddingLeft: '12px' }}>
                        <CurrencyInput
                          className="currencyLabel"
                          prefix="$"
                          value={parseFloat(userInfo?.baseWage).toFixed(2)}
                        />
                      </Typography>
                    </Typography>
                  </li>
                  <li style={{ alignSelf: 'center' }}>
                    <Typography
                      sx={{ display: 'flex' }}
                      component={'span'}
                      className={classes.label1}>
                      <Typography
                        component={'span'}
                        className={classes.label1}
                        sx={{ width: '180px !important' }}>
                        Base Pay (Biweekly Pay):
                      </Typography>
                      <Typography
                        component={'span'}
                        className={classes.label2}
                        sx={{ paddingLeft: '12px' }}>
                        <CurrencyInput
                          className="currencyLabel"
                          prefix="$"
                          value={parseFloat(userInfo?.basePay).toFixed(2)}
                        />
                      </Typography>
                    </Typography>
                  </li>
                  <li style={{ alignSelf: 'center' }}>
                    <Typography
                      sx={{ display: 'flex' }}
                      component={'span'}
                      className={classes.label1}>
                      <Typography
                        component={'span'}
                        className={classes.label1}
                        sx={{ width: '100px !important' }}>
                        Additional Pay:
                      </Typography>
                      <Typography
                        component={'span'}
                        className={classes.label2}
                        sx={{ paddingLeft: '12px' }}>
                        <CurrencyInput
                          className="currencyLabel"
                          prefix="$"
                          value={
                            userInfo?.additionalPay
                              ? parseFloat(userInfo?.additionalPay).toFixed(2)
                              : 0
                          }
                        />
                      </Typography>
                    </Typography>
                  </li>
                  <li style={{ alignSelf: 'center', flex: 1 }}>
                    <Typography
                      sx={{ paddingRight: '15px', textAlign: 'end' }}
                      component="div">
                      <a href={'#'} onClick={handleOpen2}>
                        <Typography
                          component={'span'}
                          className={classes.label1}
                          sx={{ textDecoration: 'underline' }}>
                          Edit
                        </Typography>
                      </a>
                    </Typography>
                  </li>
                </Grid>
              </Grid>
            </ul>

            {/* <Grid item md={12} sx={{ paddingTop: '10px' }}>
              <Grid
                container
                sx={{ backgroundColor: '#ffffff', padding: '15px' }}>
                <Grid item md={3}>
                  <Typography component={'span'} className={classes.label1}>
                    Base Wage:
                    <Typography
                      component={'span'}
                      className={classes.label2}
                      sx={{ paddingLeft: '12px' }}>
                      $10,000
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography component={'span'} className={classes.label1}>
                    Base Pay (Biweekly Pay):
                    <Typography
                      component={'span'}
                      className={classes.label2}
                      sx={{ paddingLeft: '12px' }}>
                      $10,00
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography component={'span'} className={classes.label1}>
                    Do Not Pay:
                    <Typography
                      component={'span'}
                      className={classes.label2}
                      sx={{ paddingLeft: '12px' }}>
                      No
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container sx={{ padding: '10px', paddingTop: '10px' }}>
            <Grid item md={12}>
              <Typography component={'span'} className={classes.head2}>
                Current Pay Period Settings{' '}
                <span
                  style={{
                    fontSize: '14px',
                  }}>
                  (Resets every payment cycle)
                </span>
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ paddingTop: '10px' }}>
              <Typography
                sx={{ display: 'flex' }}
                component={'span'}
                className={classes.label1}>
                <Typography
                  component={'span'}
                  className={classes.label1}
                  sx={{
                    alignSelf: 'center',
                    marginLeft: '1em',
                    width: '80px !important',
                  }}>
                  Do Not Pay:
                </Typography>
                <Typography
                  component={'span'}
                  className={classes.label2}
                  sx={{ paddingLeft: '12px' }}>
                  <Checkbox
                    sx={{
                      '& .MuiCheckbox-root': {
                        color: '#1AB23D !important',
                      },
                      color: '#1AB23D !important',
                      padding: '0px !important',
                    }}
                    color={'success'}
                    size={'medium'}
                    checked={isConsultantDoNotPay || false}
                    onClick={handleConsultantDoNotPay}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ padding: '10px', paddingTop: '10px' }}>
            <Grid item md={12}>
              <Typography component={'span'} className={classes.head2}>
                Leave History
                <a
                  onClick={downloadPdf}
                  href={'javascript: void(0);'}
                  style={{
                    color: '#Db2426',
                    fontFamily: 'Red Hat Display SemiBold',
                    fontSize: '14px',
                    paddingLeft: '10px',
                  }}>
                  Download PDF
                </a>
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ paddingTop: '10px' }}>
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell align="center">
                        Work Auth
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Leave App Recd
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Leave Start Date
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Leave End Date
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        ADP Updated
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Paid? (Y/N/P)
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Remote Work (Y/N)
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Leave Type
                      </StyledTableCell>
                      <StyledTableCell align="center">Country</StyledTableCell>
                      <StyledTableCell align="center">
                        Obtain I-94
                      </StyledTableCell>
                      <StyledTableCell align="center">Comments</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {leaveHistory.map((row: any, index: number) => {
                      let leaveTypesText = '';
                      if (row?.leaveTypes && row?.leaveTypes.length) {
                        row?.leaveTypes.map((item: any) => {
                          leaveTypesText += item?.leaveType?.type + ', ';
                        });
                      }
                      if (leaveTypesText) {
                        leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
                      }
                      return (
                        <StyledTableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <StyledTableCell2 component="th" scope="row">
                            {row.status === 'approved' &&
                            (moment(moment().format('YYYY-MM-DD')).isBetween(
                              moment(row.fromDate).format('YYYY-MM-DD'),
                              moment(row.toDate).format('YYYY-MM-DD'),
                            ) ||
                              moment(moment().format('YYYY-MM-DD')).isSame(
                                moment(row.fromDate).format('YYYY-MM-DD'),
                              ) ||
                              moment(moment().format('YYYY-MM-DD')).isSame(
                                moment(row.toDate).format('YYYY-MM-DD'),
                              ))
                              ? 'On Leave'
                              : row.status === 'pending' ||
                                row.status === 'approved'
                              ? 'Planned'
                              : row.status === 'returned'
                              ? 'Returned'
                              : row.status === 'denied'
                              ? 'Denied'
                              : row.status === 'extended'
                              ? 'Extended'
                              : row.status}
                          </StyledTableCell2>
                          <StyledTableCell2 component="th" scope="row">
                            {userInfo?.workAuth}
                          </StyledTableCell2>
                          <StyledTableCell2 component="th" scope="row">
                            {moment(row.createdAt).format('MM/DD/YYYY')}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {moment(row.fromDate).format('MM/DD/YYYY')}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {moment(row.toDate).format('MM/DD/YYYY')}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.adpUpdated}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.wishToBePaidOnLeave === 'yes'
                              ? 'Y'
                              : row.wishToBePaidOnLeave === 'no'
                              ? 'N'
                              : row.wishToBePaidOnLeave === 'partialyPaid'
                              ? 'P'
                              : ''}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.workingWhileOnLeave === null
                              ? ''
                              : row.workingWhileOnLeave
                              ? 'Y'
                              : 'N'}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {leaveTypesText}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.onLeaveCountry}
                          </StyledTableCell2>
                          <StyledTableCell2
                            align="left"
                            style={{ textTransform: 'capitalize' }}>
                            {row.obtainI94}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.comments}
                          </StyledTableCell2>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Typography>
      )}
    </Typography>
  );
};

export default ConsultantsTimesheet;
