// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import MailIcon from '@mui/icons-material/Mail';
// import MenuIcon from '@mui/icons-material/Menu';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Avatar, Button, ClickAwayListener, Fade, Paper } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import notification from 'assets/images/notification.svg';
import profileimg from 'assets/images/profileimg.png';
import SidebarLogo from 'assets/images/RiseIt.svg';
import { AdminPrivateRoutes } from 'config';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { RestrictionProd } from 'utils/Config';
import { MASTER_ENDPOINT, TIMESHEET } from 'utils/Constant';

import Header from './Header';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#340000',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: drawerWidth,
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#340000',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const openedImage = (theme: Theme): CSSObject => ({
  display: 'table',
  margin: '25px 20px 30px 35px',
  width: '146px',
});

const closeImage = (theme: Theme): CSSObject => ({
  display: 'table',
  margin: '30px auto 40px',
  width: '95%',
});

const loginimgopen = (theme: Theme): CSSObject => ({
  display: 'flex',
  justifyContent: 'space-evenly',
});

const loginimgclose = (theme: Theme): CSSObject => ({
  display: 'table',
  margin: '0 auto',
  textAlign: 'center',
});

const verticalbaropen = (theme: Theme): CSSObject => ({
  borderLeft: '1px solid #fff',
  height: '30px',
  marginTop: '5px',
  opacity: '0.4',
});

const verticalbarclose = (theme: Theme): CSSObject => ({
  borderBottom: '1px solid #fff',
  margin: '10px 0 8px',
  opacity: '0.4',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: '#fff',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#fff',
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: '95.5%',

  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    '& .sidebar-inner': openedImage(theme),
    '& .sidebaravatar': loginimgopen(theme),
    '& .vertical-bar': verticalbaropen(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    '& .sidebar-inner': closeImage(theme),
    '& .sidebaravatar': loginimgclose(theme),
    '& .vertical-bar': verticalbarclose(theme),
  }),
}));

const MiniDrawer = (props) => {
  const [notificationList, setNotificationList] = React.useState<any>([]);
  const [notificationCount, setNotificationCount] = React.useState<number>();

  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  React.useEffect(() => {
    getNotification(userInfo?.id);
  }, [userInfo?.id]);

  const getNotification = (userId: string) => {
    getAllListData(TIMESHEET.Notification).then((resp: any) => {
      setNotificationList(resp?.data);
      setNotificationCount(resp?.total);
    });
  };

  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open1, setOpen1] = React.useState(false);
  const [placement1, setPlacement1] = React.useState<PopperPlacementType>();

  const handleClick1 =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
      setOpen1((prev) => placement1 !== newPlacement || !prev);
      setPlacement1(newPlacement);
    };

  const clearNotification = (id: string) => {
    deleteData(id, TIMESHEET.Notification + '/clear').then((resp: any) => {
      getNotification(userInfo?.id);
    });
  };

  // const theme = useTheme();
  // const [open, setOpen] = React.useState(true);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  const [imageId, setImageId] = React.useState<string>('');

  React.useEffect(() => {
    setImageId(userInfo?.imageId);
  }, [userInfo?.imageId]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {props.showHeader && (
        <AppBar position="fixed" open={true} style={{ boxShadow: 'unset' }}>
          <Toolbar sx={{ display: 'inline' }}>
            <Typography variant="h6" noWrap component="div">
              <Header from={props.from} />
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Drawer className="siderbg" variant="permanent" open={true}>
        <Typography component={'div'} className="d-flex-ja flex-column">
          <img className="sidebar-inner" src={SidebarLogo} alt="Riseit" />
        </Typography>
        <List className="sidebar-menu">
          {/* <Link to={AdminPrivateRoutes.DASHBOARD} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'dashboard' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'dashboard' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span
                    style={{ opacity: props.from === 'dashboard' ? 1 : 0.4 }}>
                    DashBoard
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link> */}
          <Link to={AdminPrivateRoutes.CONSULTANTS} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={
                props.from === 'consultants' ||
                props.from === 'consultantsSub' ||
                props.from === 'projects'
                  ? 'sideNavactive'
                  : ''
              }>
              <ListItemButton
                style={{
                  paddingLeft:
                    props.from === 'consultants' ||
                    props.from === 'consultantsSub' ||
                    props.from === 'projects'
                      ? 17
                      : 20,
                }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span
                    style={{
                      opacity:
                        props.from === 'consultants' ||
                        props.from === 'consultantsSub' ||
                        props.from === 'projects'
                          ? 1
                          : 0.4,
                    }}>
                    Consultants
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={AdminPrivateRoutes.CLIENTS} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'clients' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'clients' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span style={{ opacity: props.from === 'clients' ? 1 : 0.4 }}>
                    Clients
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={AdminPrivateRoutes.QUEUE} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'queue' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'queue' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span style={{ opacity: props.from === 'queue' ? 1 : 0.4 }}>
                    Queue
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          {!RestrictionProd && (
            <Link to={AdminPrivateRoutes.PAYROLLSUMMARY} className={'sidebarA'}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'payroll' ? 'sideNavactive' : ''}>
                <ListItemButton
                  style={{ paddingLeft: props.from === 'payroll' ? 17 : 20 }}
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2.5,
                  }}>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    <span
                      style={{ opacity: props.from === 'payroll' ? 1 : 0.4 }}>
                      Payroll Summary
                    </span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          )}
          <Link to={AdminPrivateRoutes.LEAVETRACKER} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'leavetracker' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'leavetracker' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span
                    style={{
                      opacity: props.from === 'leavetracker' ? 1 : 0.4,
                    }}>
                    Leave Tracker
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={AdminPrivateRoutes.USERS} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'users' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'users' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span style={{ opacity: props.from === 'users' ? 1 : 0.4 }}>
                    Users
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={AdminPrivateRoutes.ADMINS} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'admins' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'admins' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span style={{ opacity: props.from === 'admins' ? 1 : 0.4 }}>
                    Admins
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={AdminPrivateRoutes.PAYPERIOD} className={'sidebarA'}>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              className={props.from === 'PayPeriod' ? 'sideNavactive' : ''}>
              <ListItemButton
                style={{ paddingLeft: props.from === 'PayPeriod' ? 17 : 20 }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  minHeight: 48,
                  px: 2.5,
                }}>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <span
                    style={{ opacity: props.from === 'PayPeriod' ? 1 : 0.4 }}>
                    Pay Period
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
        {/* <Divider className="admidSidebarDivider" />
        <DrawerHeader className="sidebar-toggle">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              marginRight: 5,
              minWidth: 3,
              mr: open ? 3 : 'auto',
              ...(!open && { display: 'none' }),
            }}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader> */}
        <Box className="login-area">
          <Typography component={'div'} className="sidebaravatar">
            <Link to={AdminPrivateRoutes.MYPROFILE} className={'sidebarA'}>
              <Avatar
                alt="prifile"
                src={
                  imageId
                    ? `${process.env.REACT_APP_APIURL}${
                        MASTER_ENDPOINT.Files
                      }/${String(imageId)}`
                    : profileimg
                }
                sx={{
                  background: '#e7e7e7',
                  borderRadius: '10%',
                  height: 40,
                  width: 40,
                }}
              />
            </Link>
            <Typography component={'div'} className="vertical-bar" />
            <Button
              sx={{
                border: 'unset !important',
                color: 'unset !important',
              }}
              variant="text"
              onClick={handleClick1('bottom-end')}>
              <img src={notification} alt="Riseit" />
              {notificationCount && notificationCount !== 0 ? (
                <Typography
                  component={'div'}
                  sx={{
                    background: '#DB2426',
                    borderRadius: '4px',
                    height: '8px',
                    position: 'absolute',
                    right: '8px',
                    top: '0px',
                    width: '8px',
                  }}
                />
              ) : (
                <Typography component={'div'} />
              )}
            </Button>
          </Typography>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {props.showHeader && <DrawerHeader />}
        <Typography paragraph>{props.childComProps}</Typography>
      </Box>
      {open1 && (
        <ClickAwayListener onClickAway={() => setOpen1(false)}>
          <Popper
            sx={{
              zIndex: 10000,
            }}
            open={open1}
            anchorEl={anchorEl1}
            placement={placement1}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="model-widget">
                  <Typography
                    sx={{
                      maxHeight: '500px',
                      overflowY: 'scroll',
                      width: '486px',
                    }}
                    className="request-payment-alert">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2">
                      Notification
                    </Typography>
                    {notificationList.length ? (
                      notificationList.map((row: any, index: number) => {
                        return (
                          <Typography
                            key={index}
                            component={'div'}
                            onClick={() => {
                              clearNotification(row?.id);
                            }}
                            sx={{
                              cursor: 'pointer !important',
                            }}
                            className="total-payment">
                            <Typography
                              sx={{
                                fontSize: '15px !important',
                              }}>
                              {row.message}
                            </Typography>
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography component={'div'} className="total-payment" />
                    )}
                    <Typography className="model-btn" component={'div'}>
                      <Button variant="text" onClick={() => setOpen1(false)}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          clearNotification('');
                        }}>
                        Clear All
                      </Button>
                    </Typography>
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default MiniDrawer;
