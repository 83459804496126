import {
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdminPrivateRoutes } from 'config';
// import EditableLabel from 'editable-label-react';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { USER_PAYROLL_SUMMARY_BACK_ACTION } from 'store/types/UserTypes';
import { roundOfData } from 'utils/Config';
import { PAYCODETYPES } from 'utils/Constant';

import useStyles from '../PrivateStyle';

type AccordianProps = {
  index: number;
  item: any;
  skip: any;
  expanded: string;
  setEditObject: any;
};

const PayPeriodAccordianTable = memo((props: AccordianProps) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const { item, index, skip, expanded, setEditObject } = props;
  const [tableReload, setTableReload] = React.useState<boolean>(false);
  const labelEdit = (item: any, key: string, type?: string) => {
    if (key === 'summary_notes') {
      return (
        <textarea
          defaultValue={item && item?.[key] ? item?.[key].toString() : ''}
          className="textArea"
          onChange={(e: any) => {
            const val = e.target.value;
            if (item?.[key] !== val) {
              setEditObject((pre: any) => {
                const fIndx = pre.findIndex(
                  (it: any) => it.summary_id === item.summary_id,
                );
                item[key] = val;
                if (fIndx !== -1) {
                  pre[fIndx] = item;
                } else {
                  pre.push(item);
                }
                return [...pre];
              });
              setTableReload(!tableReload);
            }
          }}
        />
      );
    }
    return (
      <input
        className="myInputEditTableClass"
        style={{
          backgroundColor: 'transparent !important',
          borderWidth: '0px !important',
          cursor: 'pointer !important',
        }}
        defaultValue={
          item?.[key]
            ? type === 'number'
              ? roundOfData(item?.[key].toString())
              : item?.[key].toString()
            : item?.[key] === 0
            ? type === 'number'
              ? roundOfData('0')
              : '0'
            : ''
        }
        onChange={(e) => {
          const val = e?.target?.value;
          if (item?.[key] !== val) {
            if (
              key === 'summary_currentHours3Amount1' &&
              item.summary_jobWcCode ===
                PAYCODETYPES.PIECEWORK_INDEPENDENT.id &&
              item?.user_consultantStatus.toLowerCase() !== 'terminated'
            ) {
              item['summary_currentEarnings3Amount2'] = Number(
                (Number(item.user_baseWage) / 2080) * Number(val),
              );
              item['summary_regEarnings'] = Number(
                Number(item.user_basePay) -
                  (Number(item.user_baseWage) / 2080) * Number(val) -
                  (Number(item.user_baseWage) / 2080) *
                    Number(item['summary_currentHours3Amount2']),
              );
            }
            if (
              key === 'summary_currentHours3Amount2' &&
              item.summary_jobWcCode ===
                PAYCODETYPES.PIECEWORK_INDEPENDENT.id &&
              item?.user_consultantStatus.toLowerCase() !== 'terminated'
            ) {
              item['summary_currentEarnings3Amount3'] = Number(
                (Number(item.user_baseWage) / 2080) * Number(val),
              );
              item['summary_regEarnings'] = Number(
                Number(item.user_basePay) -
                  (Number(item.user_baseWage) / 2080) *
                    Number(item['summary_currentHours3Amount1']) -
                  (Number(item.user_baseWage) / 2080) * Number(val),
              );
            }
            if (
              key === 'summary_currentHours3Amount2' &&
              item.summary_jobWcCode === PAYCODETYPES.PIECEWORK_INDEPENDENT.id
            ) {
              item['summary_regHours_orginal'] = Number(
                Number(item.summary_regHours) - Number(val),
              ).toFixed(2);
            }
            if (
              key === 'summary_currentHours3Amount2' &&
              (item.summary_jobWcCode === PAYCODETYPES.HOURLY.id ||
                item?.summary_jobWcCode === PAYCODETYPES.SALARY.id)
            ) {
              item['summary_currentEarnings3Amount3'] = Number(
                Number(val ? Number(val.replace(/[^0-9.-]+/g, '')) : 0) *
                  Number(item.summary_payRate ? item.summary_payRate : 0),
              ).toFixed(2);
            }
            if (key === 'summary_psAdjustedAmount') {
              item['summary_regHours_orginal'] = Number(
                Number(item.summary_regHours) + Number(val),
              ).toFixed(2);
            }

            if (type === 'number') {
              item[key] = val ? Number(val.replace(/[^0-9.-]+/g, '')) : 0;
            } else {
              item[key] = val;
            }

            // PBN
            if (key === 'summary_currentEarnings3Amount1') {
              item['summary_grossEarnings_realTime'] = Number(
                Number(val ? val.replace('$', '').replace(',', '') : 0) +
                  Number(
                    item.summary_regEarnings ? item.summary_regEarnings : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount6
                      ? item.summary_currentEarnings3Amount6
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount8
                      ? item.summary_currentEarnings3Amount8
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount2
                      ? item.summary_currentEarnings3Amount2
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount5
                      ? item.summary_currentEarnings3Amount5
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount9
                      ? item.summary_currentEarnings3Amount9
                      : 0,
                  ),
                // Don't add SIC value to Gross Earnings [PUAT-346]
                // +
                // Number(
                //   item.summary_currentEarnings3Amount3
                //     ? item.summary_currentEarnings3Amount3
                //     : 0,
                // ),
              );
            }

            if (key === 'summary_regEarnings') {
              item['summary_grossEarnings_realTime'] = Number(
                Number(
                  item.summary_currentEarnings3Amount1
                    ? item.summary_currentEarnings3Amount1
                    : 0,
                ) +
                  Number(val ? val.replace('$', '').replace(',', '') : 0) +
                  Number(
                    item.summary_currentEarnings3Amount6
                      ? item.summary_currentEarnings3Amount6
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount8
                      ? item.summary_currentEarnings3Amount8
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount2
                      ? item.summary_currentEarnings3Amount2
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount5
                      ? item.summary_currentEarnings3Amount5
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount9
                      ? item.summary_currentEarnings3Amount9
                      : 0,
                  ),
                // Don't add SIC value to Gross Earnings [PUAT-346]
                // +
                // Number(
                //   item.summary_currentEarnings3Amount3
                //     ? item.summary_currentEarnings3Amount3
                //     : 0,
                // ),
              );
            }

            // if (key === 'summary_currentEarnings3Amount3') {
            //   item['summary_grossEarnings_realTime'] = Number(
            //     Number(
            //       item.summary_currentEarnings3Amount1
            //         ? item.summary_currentEarnings3Amount1
            //         : 0,
            //     ) +
            //       Number(
            //         item.summary_regEarnings ? item.summary_regEarnings : 0,
            //       ) +
            //       Number(
            //         item.summary_currentEarnings3Amount6
            //           ? item.summary_currentEarnings3Amount6
            //           : 0,
            //       ) +
            //       Number(
            //         item.summary_currentEarnings3Amount8
            //           ? item.summary_currentEarnings3Amount8
            //           : 0,
            //       ) +
            //       Number(
            //         item.summary_currentEarnings3Amount2
            //           ? item.summary_currentEarnings3Amount2
            //           : 0,
            //       ) +
            //       Number(val ? val.replace('$', '').replace(',', '') : 0),
            //   );
            // }

            // BON
            if (key === 'summary_currentEarnings3Amount5') {
              item['summary_grossEarnings_realTime'] = Number(
                Number(
                  item.summary_currentEarnings3Amount1
                    ? item.summary_currentEarnings3Amount1
                    : 0,
                ) +
                  Number(
                    item.summary_regEarnings ? item.summary_regEarnings : 0,
                  ) +
                  Number(val ? val.replace('$', '').replace(',', '') : 0) +
                  Number(
                    item.summary_currentEarnings3Amount8
                      ? item.summary_currentEarnings3Amount8
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount2
                      ? item.summary_currentEarnings3Amount2
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount6
                      ? item.summary_currentEarnings3Amount6
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount9
                      ? item.summary_currentEarnings3Amount9
                      : 0,
                  ),
              );
            }

            // OTH
            if (key === 'summary_currentEarnings3Amount6') {
              item['summary_grossEarnings_realTime'] = Number(
                Number(
                  item.summary_currentEarnings3Amount1
                    ? item.summary_currentEarnings3Amount1
                    : 0,
                ) +
                  Number(
                    item.summary_regEarnings ? item.summary_regEarnings : 0,
                  ) +
                  Number(val ? val.replace('$', '').replace(',', '') : 0) +
                  Number(
                    item.summary_currentEarnings3Amount8
                      ? item.summary_currentEarnings3Amount8
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount2
                      ? item.summary_currentEarnings3Amount2
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount5
                      ? item.summary_currentEarnings3Amount5
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount9
                      ? item.summary_currentEarnings3Amount9
                      : 0,
                  ),
                // Don't add SIC value to Gross Earnings [PUAT-346]
                // +
                // Number(
                //   item.summary_currentEarnings3Amount3
                //     ? item.summary_currentEarnings3Amount3
                //     : 0,
                // ),
              );
            }

            // RET
            if (key === 'summary_currentEarnings3Amount8') {
              item['summary_grossEarnings_realTime'] = Number(
                Number(
                  item.summary_currentEarnings3Amount1
                    ? item.summary_currentEarnings3Amount1
                    : 0,
                ) +
                  Number(
                    item.summary_regEarnings ? item.summary_regEarnings : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount6
                      ? item.summary_currentEarnings3Amount6
                      : 0,
                  ) +
                  Number(val ? val.replace('$', '').replace(',', '') : 0) +
                  Number(
                    item.summary_currentEarnings3Amount2
                      ? item.summary_currentEarnings3Amount2
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount5
                      ? item.summary_currentEarnings3Amount5
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount9
                      ? item.summary_currentEarnings3Amount9
                      : 0,
                  ),
                // Don't add SIC value to Gross Earnings [PUAT-346]
                // +
                // Number(
                //   item.summary_currentEarnings3Amount3
                //     ? item.summary_currentEarnings3Amount3
                //     : 0,
                // ),
              );
            }

            // RBN
            if (key === 'summary_currentEarnings3Amount9') {
              item['summary_grossEarnings_realTime'] = Number(
                Number(
                  item.summary_currentEarnings3Amount1
                    ? item.summary_currentEarnings3Amount1
                    : 0,
                ) +
                  Number(
                    item.summary_regEarnings ? item.summary_regEarnings : 0,
                  ) +
                  Number(val ? val.replace('$', '').replace(',', '') : 0) +
                  Number(
                    item.summary_currentEarnings3Amount8
                      ? item.summary_currentEarnings3Amount8
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount2
                      ? item.summary_currentEarnings3Amount2
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount5
                      ? item.summary_currentEarnings3Amount5
                      : 0,
                  ) +
                  Number(
                    item.summary_currentEarnings3Amount6
                      ? item.summary_currentEarnings3Amount6
                      : 0,
                  ),
              );
            }

            setEditObject((pre: any) => {
              const fIndx = pre.findIndex(
                (it: any) => it.summary_id === item.summary_id,
              );
              if (fIndx !== -1) {
                if (type === 'number') {
                  item[key] = val ? Number(val.replace(/[^0-9.-]+/g, '')) : 0;
                } else {
                  item[key] = val;
                }
                pre[fIndx] = item;
              } else {
                if (type === 'number') {
                  item[key] = val ? Number(val.replace(/[^0-9.-]+/g, '')) : 0;
                } else {
                  item[key] = val;
                }
                pre.push(item);
              }
              return [...pre];
            });
            setTableReload(!tableReload);
          }
        }}
      />
    );
  };

  if (item?.user_consultantStatus !== 'Closed') {
    return (
      <TableRow
        key={index}
        sx={{
          background: item.summary_isVerified
            ? '#cef1c7 !important'
            : 'transparent',
        }}>
        <TableCell
          align="center"
          className={classes.colsticky}
          component="th"
          scope="row"
          sx={{
            background: item.summary_isVerified
              ? '#cef1c7 !important'
              : '#EDF3F7',
            left: '0px',
            minWidth: '50px',
          }}>
          <Typography
            component={'div'}
            sx={{
              alignItems: 'center',
              background: item.summary_isVerified
                ? '#cef1c7 !important'
                : '#EDF3F7',
              display: 'flex !important',
            }}>
            <Checkbox
              sx={{
                '& .MuiCheckbox-root': {
                  color: '#1AB23D !important',
                },
                color: '#1AB23D !important',
                padding: '0px !important',
              }}
              color={'success'}
              size={'medium'}
              defaultChecked={item.summary_isVerified}
              onChange={(e) => {
                setEditObject((pre: any) => {
                  const fIndx = pre.findIndex(
                    (it: any) => it.summary_id === item.summary_id,
                  );
                  if (fIndx !== -1) {
                    item['summary_isVerified'] = e?.target?.checked;
                    pre[fIndx] = item;
                  } else {
                    item['summary_isVerified'] = e?.target?.checked;
                    pre.push(item);
                  }
                  return [...pre];
                });
              }}
              // checked={row?.preApproved}
            />
            {parseInt(skip) + index + 1}
          </Typography>
        </TableCell>
        <TableCell
          className={classes.colsticky}
          align="left"
          sx={{
            background: item.summary_isVerified
              ? '#cef1c7 !important'
              : '#EDF3F7 !important',
            left: '50px',
            minWidth: '75px',
          }}>
          {item?.user_workAuth}
        </TableCell>
        <TableCell
          className={classes.colsticky}
          sx={{
            background: item.summary_isVerified
              ? '#cef1c7 !important'
              : '#EDF3F7 !important',
            left: '125px',
            minWidth: '200px',
          }}
          align="left">
          <NavLink
            to={`${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
              item?.user_id,
            )}?type=paysheet`}
            onClick={() => {
              dispatch({
                payload: expanded,
                type: USER_PAYROLL_SUMMARY_BACK_ACTION,
              });
            }}
            state={{ from: 'PayrollSummary' }}
            style={{ color: 'inherit', textDecoration: 'inherit' }}>
            {item?.user_lastName}, {item?.user_firstName}
          </NavLink>
        </TableCell>
        <TableCell
          sx={{
            background: item.summary_isVerified
              ? '#cef1c7 !important'
              : '#EDF3F7',
          }}
          className={classes.colsticky}
          style={{ left: '325px', minWidth: '50px' }}
          align="left">
          {/* {item?.summary_jobWcCode} */}
          {item?.user_jobWcCode}
        </TableCell>
        <TableCell align="left">{item?.user_consultantStatus}</TableCell>
        <TableCell align="left">
          <Tooltip title={item?.summary_notes ? item?.summary_notes : ''}>
            {labelEdit(item, 'summary_notes')}
          </Tooltip>
        </TableCell>
        <TableCell align="left">
          {roundOfData(item?.summary_baseWage)}
        </TableCell>
        <TableCell align="left">{roundOfData(item?.summary_basePay)}</TableCell>
        <TableCell align="left">{item?.user_companyCode}</TableCell>
        <TableCell align="left">{item?.summary_batchId}</TableCell>
        <TableCell align="left">{item?.user_empCode}</TableCell>
        <TableCell align="left">
          {item?.summary_regHours_orginal
            ? item?.summary_regHours_orginal
            : item?.summary_regHours}
        </TableCell>
        <TableCell align="left">
          {item?.summary_grossEarnings_realTime
            ? roundOfData(item?.summary_grossEarnings_realTime)
            : roundOfData(item?.summary_grossEarnings)}
        </TableCell>
        <TableCell align="left">
          {item?.summary_jobWcCode === PAYCODETYPES.HOURLY.id ||
          item?.summary_jobWcCode === PAYCODETYPES.SALARY.id
            ? roundOfData(item?.summary_regEarnings)
            : labelEdit(item, 'summary_regEarnings', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code1')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount1', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentHours3Code1')}
        </TableCell>
        <TableCell align="left">
          {item?.summary_jobWcCode === PAYCODETYPES.HOURLY.id ||
          item?.summary_jobWcCode === PAYCODETYPES.SALARY.id
            ? ''
            : labelEdit(item, 'summary_currentHours3Amount1')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code2')}
        </TableCell>
        <TableCell align="left">
          {roundOfData(item?.summary_currentEarnings3Amount2)}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentHours3Code2')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentHours3Amount2')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code3')}
        </TableCell>
        <TableCell align="left">
          {roundOfData(item?.summary_currentEarnings3Amount3)}
          {/* {labelEdit(item, 'summary_currentEarnings3Amount3', 'number')} */}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code4')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount4', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code5')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount5', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code6')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount6', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentHours3Code3')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentHours3Amount3')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code7')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount7', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code8')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount8', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Code9')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentEarnings3Amount9', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode1')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount1', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode2')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount2', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode3')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount3', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode4')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount4', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode5')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount5', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode6')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount6', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode7')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount7', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedCode8')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_currentAdjustDedAmount8', 'number')}
        </TableCell>
        <TableCell align="left">
          {labelEdit(item, 'summary_psAdjustedAmount', 'hour')}
        </TableCell>
      </TableRow>
    );
  }
  return <Typography />;
});

export default PayPeriodAccordianTable;
