export const MASTER_ENDPOINT = {
  Admins: 'users',
  Changepassword: 'password/change',
  Clients: 'clients',
  Consultants: 'consultants',
  ConsultantsDoNotPay: 'do-not-pay',
  ConsultantsPay: 'pay',
  // CreateMaster: 'master/create',
  EmailValidate: 'auth/email-validation',
  Files: 'files',
  ForgotPassword: 'password/forgot',
  LeaveTypes: 'master/leave-types',
  Leaves: 'leave',
  LeavesStatus: 'leave/status',
  Login: 'auth/login',
  PAYMENTPAYROLL: 'payroll/summary',
  Payperiods: 'payperiods',
  PayrollQuestions: 'payroll-questions',
  ProjectCollectionFrequency: 'projects/collection-frequency',
  ProjectNotes: 'projects/notes',
  ProjectsConsultant: 'projects/project-consultant',
  Queue: 'queues',
  Roles: 'master/roles',
  SetPassword: 'password/set-password',
  ValidateHash: 'password/validate',
  VersionHistory: 'version-history',
};

export const TIMESHEET = {
  Notification: 'notifications',
  TimeSheetNotesCreate: 'timesheets/notes',
  TimeSheetnotes: 'timesheets/timesheets-notes',
  TimeSheets: 'timesheets',
  TimeSheetsHours: 'timesheets/hours',
};

export const PAYSHEET: any = {
  Payroll: 'payroll',
  YtdBilled: 'projects/list',
  YtdBilledNotesAdd: 'projects/pivot/notes',
};
export const PAYCODETYPES = {
  HOURLY: {
    id: '2HOURLY',
    name: '2Hourly',
  },
  PIECEWORK_INDEPENDENT: {
    id: '3P/I',
    name: '3P/I',
  },
  SALARY: {
    id: '1SALARY',
    name: 'Salary',
  },
};
