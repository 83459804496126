import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ClickAwayListener, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
// import arrowcolumns from 'assets/images/arrowcolumns.svg';
import SearchBar from 'components/SearchBar/SearchBar';
import { AdminPrivateRoutes } from 'config';
import debouce from 'lodash.debounce';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  USER_FILTER_COMPANY_STATUS,
  USER_FILTER_COMPANY_STATUS_QUEUE,
  USER_FILTER_STATUS,
  USER_SEARCH,
} from 'store/types/UserTypes';
import { RestrictionProd } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

const Header: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const {
    status,
    companyStatus,
    companyStatusQueue,
    searchText,
    userInfo,
  }: any = useAppSelector((store: any) => store.userLogin);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open, setOpen] = React.useState(false);
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleStatusClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setStatusOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const [searchTextValue, setsearchTextValue] = React.useState('');

  useEffect(() => {
    setsearchTextValue(searchText);
  }, [searchText]);

  const onChangeText = (sts: any) => {
    dispatch({
      payload: sts?.target?.value,
      type: USER_SEARCH,
    });
  };

  const debouncedResults = React.useMemo(() => {
    return debouce(onChangeText, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props?.from === 'clients') {
      getTotalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.from, status]);

  const [totalClients, setTotalClients] = React.useState(0);
  const [totalProjects, setTotalProjects] = React.useState(0);
  const getTotalData = () => {
    let url = MASTER_ENDPOINT.Clients + '?skip=0&take=1';
    if (status) {
      const boolVal = status === 'active' ? true : false;
      url += `&status=${String(boolVal)}`;
    }
    getAllListData(url)
      .then((resp: any) => {
        setTotalClients(
          resp?.total?.[0]?.totalClients
            ? parseInt(resp?.total?.[0]?.totalClients)
            : 0,
        );
        setTotalProjects(
          resp?.total?.[0]?.totalActiveProject
            ? parseInt(resp?.total?.[0]?.totalActiveProject)
            : 0,
        );
      })
      .catch((err: any) => {});
  };

  const statusChange = (sts: any) => {
    dispatch({
      payload: sts,
      type: USER_FILTER_STATUS,
    });
    setStatusOpen(!open);
  };

  const companyStatusChange = (sts: any) => {
    dispatch({
      payload: sts,
      type: USER_FILTER_COMPANY_STATUS,
    });
    setOpen(!open);
  };

  const queueCompanyStatusChange = (sts: any) => {
    dispatch({
      payload: sts,
      type: USER_FILTER_COMPANY_STATUS_QUEUE,
    });
    setOpen(!open);
  };

  return (
    <Typography>
      <Grid container>
        <Grid item xs={2} sm={3} md={3} className="back-nav-link">
          {props.from !== 'payroll' && (
            <SearchBar
              onChange={(e) => {
                debouncedResults(e);
                setsearchTextValue(e.target.value);
              }}
              value={searchTextValue}
            />
          )}
        </Grid>
        <Grid item xs={6} sm={5} md={4} sx={{ alignSelf: 'center' }}>
          {['consultants', 'consultantsSub', 'projects'].includes(
            props.from,
          ) && (
            <Typography component="div" className="headernav">
              <List>
                <ListItem className={props.from === 'projects' ? 'active' : ''}>
                  <ListItemText>
                    <Link
                      to={AdminPrivateRoutes.PROJECTS}
                      className={'sidebarA'}>
                      Projects
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem
                  className={props.from === 'consultants' ? 'active' : ''}>
                  <ListItemText>
                    <Link
                      to={AdminPrivateRoutes.CONSULTANTS}
                      className={'sidebarA'}>
                      Submissions
                    </Link>
                  </ListItemText>
                </ListItem>
                {!RestrictionProd ? (
                  userInfo.roleId === 5 || userInfo.roleId === 4 ? (
                    <ListItem
                      className={
                        props.from === 'consultantsSub' ? 'active' : ''
                      }>
                      <ListItemText>
                        <Link
                          to={AdminPrivateRoutes.CONSULTANTSSUB}
                          className={'sidebarA'}>
                          Payroll Questions
                        </Link>
                      </ListItemText>
                    </ListItem>
                  ) : (
                    <Typography />
                  )
                ) : (
                  <Typography />
                )}
              </List>
            </Typography>
          )}
          {props.from === 'clients' && (
            <Typography
              component="div"
              className="headernav"
              sx={{
                alignSelf: 'center',
                color: '#979598',
                fontFamily: 'Red Hat Display Medium',
                fontSize: '14px',
                paddingLeft: '22px',
              }}>
              Total Clients:{' '}
              <strong style={{ fontFamily: 'Red Hat Display Bold' }}>
                {totalClients}
              </strong>{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;Total Active Projects:{' '}
              <strong style={{ fontFamily: 'Red Hat Display Bold' }}>
                {totalProjects}
              </strong>
            </Typography>
          )}
        </Grid>
        <Grid item xs={4} sm={4} md={5}>
          {[
            'consultants',
            'consultantsSub',
            'leavetracker',
            'payroll',
            'queue',
            'projects',
            'users',
          ].includes(props.from) && (
            <Typography>
              {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Popper
                    className="popwidget"
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Typography sx={{ p: 2 }}>
                            {props.from === 'queue' ? (
                              <>
                                <Typography
                                  onClick={() =>
                                    queueCompanyStatusChange('all')
                                  }
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  All
                                </Typography>
                                <Typography
                                  onClick={() =>
                                    queueCompanyStatusChange('itech')
                                  }
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  ITech
                                </Typography>
                                <Typography
                                  onClick={() =>
                                    queueCompanyStatusChange('smartworks')
                                  }
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  Smart Works
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  onClick={() => companyStatusChange('all')}
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  All
                                </Typography>
                                <Typography
                                  onClick={() =>
                                    companyStatusChange('smartworks')
                                  }
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  Smart Works
                                </Typography>
                                <Typography
                                  onClick={() => companyStatusChange('itech')}
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  ITech
                                </Typography>
                              </>
                            )}
                          </Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </ClickAwayListener>
              )}
              <Grid container justifyContent="right">
                <ul className="filterlist">
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Company:{' '}
                      {props.from === 'queue'
                        ? companyStatusQueue === 'all'
                          ? 'All'
                          : companyStatusQueue === 'itech'
                          ? 'ITech'
                          : 'Smart Works'
                        : companyStatus === 'all'
                        ? 'All'
                        : companyStatus === 'itech'
                        ? 'ITech'
                        : 'Smart Works'}
                      {'   '}
                      {open ? (
                        <KeyboardArrowUpIcon sx={{ color: '#a59999' }} />
                      ) : (
                        <KeyboardArrowDownIcon sx={{ color: '#a59999' }} />
                      )}
                    </Button>
                  </li>
                </ul>
              </Grid>
            </Typography>
          )}
          {props.from === 'clients' && (
            <Typography>
              {statusOpen && (
                <ClickAwayListener onClickAway={() => setStatusOpen(false)}>
                  <Popper
                    className="popwidget"
                    open={statusOpen}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Typography sx={{ p: 2 }}>
                            <Typography
                              onClick={() => statusChange('')}
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              All
                            </Typography>
                            <Typography
                              onClick={() => statusChange('active')}
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              Active
                            </Typography>
                            <Typography
                              onClick={() => statusChange('in-active')}
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              Inactive
                            </Typography>
                          </Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </ClickAwayListener>
              )}
              <Grid container justifyContent="right">
                <ul className="filterlist">
                  <li>
                    <Button
                      onClick={handleStatusClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Status :{' '}
                      {status === 'active'
                        ? 'Active'
                        : status === 'in-active'
                        ? 'Inactive'
                        : 'All'}
                      {'   '}
                      {statusOpen ? (
                        <KeyboardArrowUpIcon sx={{ color: '#a59999' }} />
                      ) : (
                        <KeyboardArrowDownIcon sx={{ color: '#a59999' }} />
                      )}
                    </Button>
                  </li>
                </ul>
              </Grid>
            </Typography>
          )}
          {/* {props.from === 'queue' && (
            <Typography>
              <Popper
                className="popwidget"
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 2 }}>
                        <p>iTech</p>
                        <p>RiseIT</p>
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <Grid container justifyContent="right">
                <ul className="filterlist">
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Status: Active{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Vendor: Type{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Filter: Active{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Company: iTech{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Assigned: iTech{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                </ul>
              </Grid>
            </Typography>
          )} */}
          {/* {props.from === 'admins' && (
            <Typography>
              <Popper
                className="popwidget"
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 2 }}>
                        <p>iTech</p>
                        <p>RiseIT</p>
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <Grid container justifyContent="right">
                <ul className="filterlist">
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Filter: Active{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                </ul>
              </Grid>
            </Typography>
          )} */}
          {/* {props.from === 'payroll' && (
            <Typography>
              <Popper
                className="popwidget"
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 2 }}>
                        <p>iTech</p>
                        <p>RiseIT</p>
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <Grid container justifyContent="right">
                <ul className="filterlist">
                  <li>
                    <Button
                      onClick={handleClick('bottom-end')}
                      style={{ backgroundColor: 'transparent' }}>
                      Company: iTech{' '}
                      <span>
                        <img className="sidebar-inner" src={arrowcolumns} />
                      </span>
                    </Button>
                  </li>
                </ul>
              </Grid>
            </Typography>
          )} */}
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Header;
